import * as React from 'react';
import { useEffect } from 'react';
import { bookmarksApi } from '../apis/bookmarks';
import { foldersApi } from '../apis/folders';
import { mapFoldersModel } from '../services/shared';
import { FoldersModel } from '../models/foldersModel';
import { useBookmarksContext } from '../context/BookmarksContext';
import { useFolderContext } from '../context/FolderContext';
import { useFoldersContext } from '../context/FoldersContext';
import { useMessageBoxContext } from '../context/MessageBoxContext';
import { useLoadingContext } from '../context/LoadingContext';
import { FoldersListModel } from '../models/foldersListModel';
import { useControlContext } from '../context/ControlContext';

interface FoldersControlsProps {
  library: string;
}

const FoldersControls: React.FC<FoldersControlsProps> = ({ library }) => { 
  const { Folders, setFolders } = useFoldersContext();
  const { Folder } = useFolderContext();
  const { setMessageBox } = useMessageBoxContext();
  const { Loading, setLoading } = useLoadingContext();
  const { setBookmarks } = useBookmarksContext();
  const { commandControlContext } = useControlContext();

  useEffect(() => {
    if (Loading === false) return;
    if (Folders !== null) return;
    setLoading(false);
   
    const fetchFolders = async () => {
      const result = await foldersApi(library ?? '', commandControlContext?.token ?? '');

      if (result.length === 1 && result[0].Id === 0) {
        setMessageBox('Biblioteca inexistente!');
      }
      else if (result.length === 1 && result[0].Id === -4) {
        setMessageBox(result[0].Name ?? 'Page not found!');
      }
      else if (result.length === 1 && result[0].Id === -422) {
        setMessageBox('Biblioteca inexistente!');
      }
      else if (result.length === 1 && result[0].Id === -404) {
        setMessageBox('Endereço da API não encontrado!');
      }
      else if (result.length === 1 && result[0].Id === -1) {
        setMessageBox('Sem conexão com a Internet ou servidor fora do ar! Por favor, tente novamente mais tarde.');
      }
      else {
        setFolders(result ?? null);
      }
    };

    const fetchBookmarks = async () => {
      try {
        const result = await bookmarksApi(library ?? '', commandControlContext?.token ?? '');
        setBookmarks(result);
      }
      catch {
        setMessageBox('Bookmark load failure');
      }
    }

    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
    setTimeout(() => {
      fetchBookmarks();
      fetchFolders();
    }, 200);
  }, [Loading]);

  React.useEffect(() => {
    if (Folder) {
      const procura = (item: FoldersModel[] | null, id: number): FoldersModel | null => {
        if (item == null) return null;
        for (let element of item) {
          if (element.Id === id) {
            return element;
          }
          if (element.HasSubFolders) {
            let found = procura(element.SubFolders ?? null, id);
            if (found != null) {
              return found;
            }
          }
        }
        return null;
      };
    }
  }, [Folder]);

  useEffect(() => {
    if (!(Folders === undefined || Folders === null)) {
      setFolders([]);
      let dataLista: FoldersListModel[] = [];
      mapFoldersModel(Folders, '', dataLista);
      setFolders(Folders);
    }
  }, [Folders]);
  
  return (
    <>
    </>
  )
}

export default FoldersControls;