import axios from 'axios';
import { Login, LoginResponse } from  '../models/loginModel';
import { showMessage } from '../languages/_showmessages';
import { getDocReaderAdminAPI } from '../services/appSettings';

const baseUrl = getDocReaderAdminAPI();
const url = new URL(`${baseUrl}`);

export const loginApi = async (username: string, password: string): Promise<Login> => {
  if (username === '' || password === '') {
    return { mensagem: showMessage('LoginIncorreto'), sucesso: false, token: "" } as Login;
  }

  const result: Login = await axios.post<LoginResponse>(url.toString() + "/login", {
    username,
    password
  })
    .then(response => {
      const data = response.data;

      const rowData: Login = {
        sucesso: data.sucesso,
        mensagem: data.mensagem,
        token: data.data.token,
        username: data.data.username
      };
      if (data.sucesso) {
        return rowData;
      }
      return rowData;
    })
    .catch(error => {
      if (process.env.REACT_APP_SHOW_LOGS === 'on') {
        console.error(error);
      }

      if (error.response && error.response.status === 422) {
        return { mensagem: showMessage('LoginIncorreto'), sucesso: false, token: "" } as Login;
      }
      else if (error.response && error.response.status === 404) {
        return { mensagem: showMessage('PaginaNaoencontrada'), sucesso: false, token: "" } as Login;
      }

      return { mensagem: showMessage('ErroDesconhecido'), sucesso: false, token: "" } as Login;
    }
    );
  return result;
};