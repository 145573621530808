import React, { useState, useEffect, useRef } from 'react';
import { xIcon } from '@progress/kendo-svg-icons'; 
import { SvgIcon } from '@progress/kendo-react-common'; 
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs'; 
import { useSearchTextContext } from '../../context/SearchTextContext';  
import { useControlContext } from '../../context/ControlContext';
import { isMobile } from 'react-device-detect';
import BackIcon from "../../assets/BackIcon.svg"
import SendIcon from "../../assets/SendIcon.svg"
import { showMessage } from '../../languages/_showmessages';

interface SearchBoxProps {
  isSearch: boolean;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchBoxVisible: boolean;
  setIsSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBox: React.FC<SearchBoxProps> = ({ isSearch, setIsSearch, isSearchBoxVisible, setIsSearchBoxVisible }) => {    
    const { SearchText, setSearchText } = useSearchTextContext();    
    const { setCommandControlContext, commandControlContext } = useControlContext(); 
    const [ searchTextTmp, setSearchTextTmp ] = useState<string>('');

    useEffect(() => {
      if (commandControlContext?.DocMultiMatchsTotal === null || commandControlContext?.DocMultiMatchsTotal === undefined || commandControlContext?.DocMultiMatchsTotal === 0) return;
  
      setIsSearchBoxVisible(false); // Mobile only
      setIsSearch(true);
      setSearchTextTmp(SearchText || '');
    }, [commandControlContext]);
  
    const SearchCmd = async () => {
      if (searchTextTmp === '') return; 
      setSearchText(searchTextTmp);
    }
        
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        SearchCmd();
      }
    }  
  
    const clear = () => {
      setCommandControlContext({ DocMultiMatchsTotal: 0 });
      setSearchTextTmp(''); 
      setSearchText('');
      setIsSearch(false);
    };

    return (
      <>
        {isMobile ?
          isSearchBoxVisible ?
            <div className="searchbox-mobile">
              <div onClick={() => setIsSearchBoxVisible(false)} className="close-searchBox">
                <img src={BackIcon} className='backicon-mobile' alt="back" />
              </div>
              <div className='searchmiddle-mobile'>
                <div>
                  <Input autoFocus={true} title={showMessage("pressioneEnterParaPesquisar")} className='searchinput-mobile' value={searchTextTmp} onKeyDown={handleKeyDown} onChange={(e) => { setSearchTextTmp(e.value); }} />
                </div>
              </div>
              <div className='left'>
                {!isSearch? <></> : 
                  <div onClick={clear} className='searchclear-mobile' title='Limpa a pesquisa'>
                    <SvgIcon className='closeSvgIcon' icon={xIcon} />
                  </div>
                }
                <div className='left' onClick={SearchCmd}>
                  <img className='sendicon-mobile' src={SendIcon} alt="search" />
                </div>
              </div>
            </div>
          :
            <></>
        :
          <div className='searchbox'>
            <div className='left'>
              <Input placeholder={showMessage("pesquisarNasBibliotecas")} title={showMessage("pressioneEnterParaPesquisar")} className='searchinput' value={searchTextTmp} onKeyDown={handleKeyDown} onChange={(e) => {setSearchTextTmp(e.value); }} />
            </div>
            <div className='left'>
              {!isSearch ? <></> : 
              <div onClick={clear} className='searchcleardiv' title='Limpa a pesquisa'>
                <SvgIcon className='closeSvgIcon' icon={xIcon} />
              </div>}
              <Button title={showMessage("cliqueParaPesquisar")} onClick={SearchCmd}  className='searchbutton'>{showMessage("pesquisar")}</Button>
            </div>        
          </div>          
        }
      </>
    );
  };

  export default SearchBox;