import React, { useEffect } from 'react';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import DocumentDraw from '../components/DocumentDraw';
import { buildHash, getImage } from '../apis/document';
import { ImageModel } from '../models/imageModel';
import { useSearchContext } from '../context/SearchContext';
import { useSearchTextContext } from '../context/SearchTextContext';
import { useMessageBoxContext } from '../context/MessageBoxContext';
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from '@progress/kendo-react-indicators';
import { useLoadingContext } from '../context/LoadingContext';
import { FoldersModel } from '../models/foldersModel';
import { isMobile } from 'react-device-detect';
import { searchApi } from '../apis/search';
import FolderEmpty from "../assets/FolderEmpty.svg"
import { showMessage } from '../languages/_showmessages';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';
import DocTypeComponent from '../components/DocType';
import ThumbsGrid from '../components/Thumbs/ThumbsGrid';
import { useLibraryContext } from '../context/LibraryContext';
import FoldersControls from '../components/FoldersControls';
import SharedSocialButtons from '../components/SharedSocialButtons';
import { ExportPdfControl } from '../components/ExportPdf';
import FoldersTreeView from '../components/FoldersTreeView';
import LibraryDetails from '../components/LibraryDetails';
import ThumbsMenu from '../components/Thumbs/ThumbsMenu';
import { Header } from '../components/DocReader/Header';
import { folderIcon, infoCircleIcon, searchIcon, downloadIcon, thumbnailsDownIcon, userIcon } from '@progress/kendo-svg-icons';
import { useControlContext } from '../context/ControlContext';
import { Footer } from '../components/DocReader/Footer';
import BookmarksControl from '../components/Bookmarks';
import { useBookmarksContext } from '../context/BookmarksContext';
import { useNavigate } from 'react-router-dom';

interface DocReaderProps {
  library: string;
}

export const DocReader: React.FC<DocReaderProps> = ({ library }) => {
  const [ image, setImage ] = React.useState<ImageModel | null>(null);
  const [ cachedImages, setCachedImages ] = React.useState<ImageModel[]>([]);
  const { Folder } = useFolderContext();
  const { Page } = usePageContext();
  const { PageLoad, setPageLoad } = usePageLoadContext();
  const { setSearch } = useSearchContext();
  const { SearchText } = useSearchTextContext();
  const { messageBox, setMessageBox } = useMessageBoxContext();
  const { Loading, setLoading } = useLoadingContext();
  const { Library } = useLibraryContext();
  const [ expanded ] = React.useState(false);
  const [ isSearch, setIsSearch ] = React.useState(false);
  const [ isFoldersVisible, setIsFoldersVisible ] = React.useState(false);
  const [ isBookmarksVisible, setIsBookmarksVisible ] = React.useState(false);
  const [ isPdfVisible, setIsPdfVisible ] = React.useState(false);
  const [ isLibraryDetailsVisible, setIsLibraryDetailsVisible ] = React.useState(false);
  const [ isThumbsMenuVisible, setIsThumbsMenuVisible] = React.useState<boolean>(false);
  const [ isThumbsVisible, setIsThumbsVisible] = React.useState<boolean>(false);
  const { commandControlContext, setCommandControlContext } = useControlContext();
  const [ isSearchBoxVisible, setIsSearchBoxVisible ] = React.useState<boolean>(false);
  const anchor = React.useRef<HTMLSpanElement>(null);
  const { Bookmarks } = useBookmarksContext();
  const navigate = useNavigate();

  // Hook para atualizar a Pagina na tela
  useEffect(() => {
    // Carrega a imagem
    const fetchImage = async (folder: FoldersModel, page: number): Promise<boolean> => {
      try {
        if (folder?.Id === 0 || page === 0) return false;

        setLoading(true);

        const hashCurrent = buildHash(SearchText ?? '', library, folder?.Id, page);

        //// Pega a imagem do cache, quando já acessou antes
        const cachedImage = cachedImages.find(h => h.hash === hashCurrent);
        if (cachedImage) {
          setImage(cachedImage);
          setLoading(false);
          return false;
        }

        // Pega a imagem do servidor
        const resultImage = await getImage(hashCurrent, library, folder?.Id, page, commandControlContext?.token ?? '');

        setLoading(false);

        if (resultImage === null) return false;

        if (!resultImage.sucesso) {
          setImage(null);
          setMessageBox(resultImage.mensagem ?? 'Falha geral');
          return false;
        }

        if (resultImage.hash === undefined) return false;

        setCachedImages(cachedImages => [...cachedImages, resultImage]);

        setImage(resultImage);
        window.history.replaceState(null, "", "/" + library + "/pageid/" + resultImage?.pageID);

        return true;
      }
      catch (error) {
        setLoading(false);
        console.error('Error fetching image:', error);
        return false;
      }
    }

    if (!PageLoad) return;

    setTimeout(() => {
      setPageLoad(false);
    }, 100);

    // Pasta sem páginas
    if (Folder === null || Page === null || Page === 0) {
      setImage({ base64: FolderEmpty } as ImageModel);
      setPageLoad(true);
      return;
    }

    fetchImage(Folder, Page);
  }, [PageLoad]);

  // Hook para fazer a pesquisa
  useEffect(() => {
    const search = async (): Promise<boolean> => {
      try {
        if (SearchText === '' || SearchText === null || SearchText === undefined) {
          window.history.replaceState(null, "", "/" + library);
          return false;
        }

        setLoading(true);
        let searchTmp = await searchApi(library ?? '', SearchText, commandControlContext?.token ?? '');
        setLoading(false);
        
        if (searchTmp?.status === 'ok') {
          if (searchTmp.pagesCount > 0) {
            searchTmp.matchs.sort((a, b) => a.folderID - b.folderID || a.page - b.page); //Ordena as ocorrencias por folder/page
            setSearch(searchTmp);
            window.history.replaceState(null, "", "/" + library + "/search/" + SearchText);
          }
          else {
            setSearch(null);
            setMessageBox(showMessage("semOcorrencias"));
            window.history.replaceState(null, "", "/" + library);
          }
        }
        else {
          setSearch(null);
          setMessageBox(showMessage("semOcorrencias"));
          window.history.replaceState(null, "", "/" + library);
        }
      } catch (error) {
        setLoading(false);
        setSearch(null);
        setMessageBox(" " + error);
      }

      return true;
    }

    search()
  }, [SearchText]);

  // Hook para já entrar no acervo com o Thumbs e/ou lista de pastas aberto(s)
  useEffect(() => {
    if (Library != null) {
      setIsThumbsVisible(Library?.thumbs > 0 ? true : false);
      setIsFoldersVisible(Library?.folderOpened > 0 ? true : false);
    }     
  }, [Library?.thumbs, Library?.folderOpened]);

  const closeMessageBox = () => {
    setMessageBox(null);
  };

  const bookmarkIcon = {
    name: 'bookmark',
    content: '<svg height="155" viewBox="0 0 118 155" width="118" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M56.0783 107.382C55.4041 108.47 54.645 109.505 53.8083 110.474C41.3062 122.797 28.7751 135.09 16.2149 147.353C14.0892 149.468 11.7634 151.374 9.27033 153.043C5.04205 155.798 1.705 154.135 1.00105 149.114C0.880203 147.788 0.852448 146.455 0.917897 145.124C0.964697 113.828 0.930928 82.5317 1.11293 51.2362C1.18378 38.9257 1.66869 26.6152 2.12109 14.3105C2.32537 11.6584 2.7779 9.03129 3.47299 6.46332C4.21009 3.25696 5.09796 2.39559 8.47798 2.1245C15.3478 1.57324 22.2313 0.939644 29.1155 0.846904C54.251 0.510311 79.3897 0.402671 104.527 0.150391C107.856 0.184034 111.148 0.847844 114.229 2.10645C114.975 2.3557 115.635 2.81229 116.13 3.42268C116.625 4.03307 116.935 4.77187 117.024 5.55211C117.262 7.08419 117.366 8.63381 117.335 10.1839C115.675 53.3361 114.86 96.4946 116.072 139.676C116.054 143.084 115.767 146.486 115.213 149.848C114.88 152.368 112.483 153.852 109.719 153.48C106.933 153.093 104.387 151.697 102.565 149.56C88.7036 134.096 71.7678 122.033 56.0783 107.382ZM108.629 7.75387L10.4976 8.46337C10.3676 9.60868 10.2481 10.2502 10.2331 10.8935C10.083 17.6564 9.79098 24.4206 9.83713 31.1822C10.0789 66.5829 10.3705 101.983 10.712 137.382C10.7185 138.076 10.8115 138.769 10.8999 139.936C12.092 138.828 12.9111 138.112 13.6788 137.347C25.9464 125.103 38.2079 112.855 50.4634 100.602C54.9348 96.123 57.7733 96.0063 62.4611 100.122C68.3898 105.326 74.414 110.423 80.3179 115.656C88.7133 123.096 97.0353 130.619 105.398 138.096C105.947 138.504 106.521 138.878 107.115 139.218C104.65 95.2053 107.356 51.7531 108.629 7.75387Z" fill="black"/></g><defs><clipPath id="clip0"><rect fill="white" height="155" transform="translate(0.777344)" width="117"/></clipPath></defs></svg>',
    viewBox: '-20 0 160 160'
  };
  
  let items = [];

    items = [
      { text: 'folders', svgIcon: folderIcon },
      (((Library?.bookmarkDisable) ?? 0) === 0 && (Bookmarks?.length ?? 0) > 0) ? { text: 'bookmarks', svgIcon: bookmarkIcon } : null,
      { text: 'zoom', svgIcon: searchIcon },
      { text: 'export', svgIcon: downloadIcon },
      { text: 'info', svgIcon: infoCircleIcon },    
      { text: 'thumbs', svgIcon: thumbnailsDownIcon },
      // (commandControlContext !== null && commandControlContext?.userName !== "") ? { text: 'logout', svgIcon: userIcon } : null
    ];

  const onSelect = (e: any) => {
    switch(e.itemTarget.props.text) {        
      case 'folders': {
        setIsFoldersVisible(!isFoldersVisible);
        setIsBookmarksVisible(false);
        setIsPdfVisible(false);
        setIsLibraryDetailsVisible(false);
        return;
      }
      
      case 'bookmarks': {
        setIsBookmarksVisible(!isBookmarksVisible);
        setIsFoldersVisible(false);
        setIsPdfVisible(false);
        setIsLibraryDetailsVisible(false);
        return;
      }
      
      case 'zoom': {
        setCommandControlContext({ isZoomVisible: !commandControlContext?.isZoomVisible });
        return;
      }

      case 'export': {
        if (Library?.exportDisable === 1) {
          console.debug(Library);
          setMessageBox(Library?.exportDisableMsg === null ? showMessage("exportdisablemsg") : Library?.exportDisableMsg);
        }
        else {
          setIsFoldersVisible(false);
          setIsBookmarksVisible(false);
          setIsPdfVisible(!isPdfVisible);
          setIsLibraryDetailsVisible(false);
        }
        return;
      }
      
      case 'info': {
        setIsFoldersVisible(false);
        setIsBookmarksVisible(false);
        setIsPdfVisible(false);
        setIsLibraryDetailsVisible(!isLibraryDetailsVisible);
        return;
      }
      
      case 'thumbs': {
        setIsThumbsMenuVisible(!isThumbsMenuVisible);
        return;
      }

      case 'logout': {
        setCommandControlContext(null);
        navigate("/login?redirectto=" + window.location.href.replace("?redirectto=" + window.location.href, ""));
        return;
      }
    }
  };

  return (
    <>
      <FoldersControls library={library} />

      {isMobile ?
        <>
          {Loading ?
            <span className='loader-mobile'>
              <Loader type='converging-spinner' />
            </span>
            : <></>
          }

          <Header isSearch={isSearch} setIsSearch={setIsSearch} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible}/>

          <div id='docpro' className='maindiv'>
            <DocumentDraw image={image ?? undefined} />
          </div>

          {Library?.docType ?? 0 > 0 ? <DocTypeComponent library={library} folder={Folder?.Id ?? 0} page={Page ?? 0} /> : <></>}

          <Footer isBookmarksEnabled={Library?.bookmarkDisable === 0 && (Bookmarks?.length ?? 0) > 0 ? false : true}
                  isBookmarksVisible={isBookmarksVisible} setIsBookmarksVisible={setIsBookmarksVisible} 
                  isFoldersVisible={isFoldersVisible} setIsFoldersVisible={setIsFoldersVisible} 
                  isThumbsVisible={isThumbsVisible} setIsThumbsVisible={setIsThumbsVisible}
                  isThumbsMenuVisible={isThumbsMenuVisible} setIsThumbsMenuVisible={setIsThumbsMenuVisible} 
                  isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible} />

          <ThumbsMenu isVisible={isThumbsMenuVisible} setIsVisible={setIsThumbsMenuVisible} />

          <FoldersTreeView isVisible={isFoldersVisible} setIsVisible={setIsFoldersVisible}/>

          <ThumbsGrid isVisible={isThumbsVisible} setIsVisible={setIsThumbsVisible} library={library} />

          <BookmarksControl isVisible={isBookmarksVisible} setIsVisible={setIsBookmarksVisible} />

          {messageBox !== null && (
            <Dialog onClose={closeMessageBox} width={300} height={400} closeIcon={true} title={"Aviso"} themeColor='primary' >
              <div className='messageboxdiv' >
                {messageBox}
              </div>
            </Dialog>
          )}
        </>
        :
        <>
          {Loading ?
            <span className='loader'>
              <Loader type='converging-spinner' />
            </span>
            : <></>
          }

          <Header isSearch={isSearch} setIsSearch={setIsSearch} isSearchBoxVisible={isSearchBoxVisible} setIsSearchBoxVisible={setIsSearchBoxVisible}/>

          <Drawer expanded={expanded} position={'start'} mode={'push'} mini={true} items={items.map((item) => ({...item}))} onSelect={onSelect}>          
            <DrawerContent>
              <FoldersTreeView isVisible={isFoldersVisible} setIsVisible={setIsFoldersVisible} />

              <BookmarksControl isVisible={isBookmarksVisible} setIsVisible={setIsBookmarksVisible} />

              {isLibraryDetailsVisible ? <LibraryDetails isVisible={isLibraryDetailsVisible} setIsVisible={setIsLibraryDetailsVisible} /> : null}

              <ExportPdfControl library={library} isVisible={isPdfVisible} setIsVisible={setIsPdfVisible} />

              <ThumbsMenu isVisible={isThumbsMenuVisible} setIsVisible={setIsThumbsMenuVisible}/>

              <div id='docpro' className='maindiv'>
                <DocumentDraw image={image ?? undefined} />
              </div>

              {Library?.docType ?? 0 > 0 ? <DocTypeComponent library={library} folder={Folder?.Id ?? 0} page={Page ?? 0} /> : <></>}
            </DrawerContent>
          </Drawer>

          <ThumbsGrid isVisible={isThumbsVisible} setIsVisible={setIsThumbsVisible} library={library} />

          {Library?.shareDisable != 1 ? <SharedSocialButtons /> : null}

          {messageBox !== null && (
            <Dialog onClose={closeMessageBox} width={450} height={200} closeIcon={true} title={"Aviso"} themeColor='primary' >
              <div className='messageboxdiv'>
                  {messageBox}
              </div>
            </Dialog>
          )}
        </>
      }
    </>
  );
} 