import React, { useState, useEffect } from 'react';
import { FoldersModel } from '../models/foldersModel';
import { FoldersListModel } from '../models/foldersListModel';
import { filterIcon, xIcon } from '@progress/kendo-svg-icons'; 
import { SvgIcon } from '@progress/kendo-react-common'; 
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { findFolderById, mapFoldersModel } from '../services/shared';
import { Loader } from '@progress/kendo-react-indicators';
import { showMessage } from '../languages/_showmessages';
import { usePageLoadContext } from '../context/PageLoadContext';
import { useFoldersContext } from '../context/FoldersContext';
import { useLibraryContext } from '../context/LibraryContext';

interface LibraryDetailsProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const LibraryDetails: React.FC<LibraryDetailsProps> = ({ isVisible, setIsVisible }) => {
  const [ filterValue, setFilterValue ] = React.useState();
  const [ data, setData ] = useState<FoldersListModel[]>([]);
  const [ iniciado, setIniciado ] = useState<boolean>(false);
  const [ dataResult, setDataResult ] = useState<FoldersListModel[]>([]);
  const { setFolder } = useFolderContext();
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();
  const { Library } = useLibraryContext();
  const { Folders } = useFoldersContext();
  const [ loading, setLoading ] = useState<boolean>(false);
 
  const onFilterChange = (ev: any) => {
    let value = ev.value; 
    setFilterValue(ev.value);
    let newData = data.filter((item) => {
      let match = false;      
        if (
          item.Name
            .toString()
            .toLocaleLowerCase()
            .indexOf(value.toLocaleLowerCase()) >= 0
        ) {
          match = true;
        }              
      return match;
    });

    setDataResult(newData);
  };

  const onRowClick = async (e: any) => {
    const folder : FoldersModel = e.dataItem.Folders;
    if (folder == null) return;
    
    setFolder(folder);
    setPage(folder?.Pages === 0 ? 0 : 1);

    setTimeout(() => {
      setPageLoad(true);
    }, 500);
  };

  useEffect(() => {
    let dataLista: FoldersListModel[] = [];    
    mapFoldersModel(Folders ?? [], '', dataLista);
    setDataResult(dataLista);
    setData(dataLista);
    setIsVisible(true);
    setLoading(false);
  }, [iniciado]);   

  if (!iniciado) {
    setIniciado(true);
    setLoading(true);
  }
 
  return (
    <div>      
      {isVisible  ?
      
      <div className={'leftpanel'}>
        {loading ? 
          <span className='loader'>
            <Loader type='converging-spinner' />                    
          </span>
        : <></>
        }
          <div className='leftpanelHeader'>                  
            <div className='leftpanelTitle'>{showMessage("informacoes")}</div>   
            <div onClick={()=> setIsVisible(false)} className='leftpanelClose'>
                <span><SvgIcon className='closeSvgIcon' icon={xIcon} /></span>  
            </div>       
            <div className='leftpanelFilter' >
              <span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon}/> </span>
              <Input className='leftpanelFilterInput'
                value={filterValue}
                onChange={onFilterChange}
                placeholder={showMessage("filtrar") + '...'}
              /> 
            </div>
          </div>                  

          <div className='libraryDetail'>
            <span className='topdiv'>
              <span className='labelText'>{showMessage("alias")}:&nbsp;{Library?.name ?? '' }</span>
              <span className='labelText'>{showMessage("nome")}:&nbsp;{Library?.description ?? '' }</span>
            </span>
            
            <Grid className='gridFolders' data={dataResult} size={'small'} onRowClick={onRowClick}>          
              <Column className='smallCount' field='Counter' title='#' width='35px' />     
              <Column className='textleft' field='Name' title='Pasta' width='320px' />
              <Column className='textright' field='PagesTotal' title={showMessage("paginas")} width='70px' />
            </Grid>      
        
            <span className='bottomdiv'>
              <span className='left'>{showMessage("totalDePastas")}: {dataResult.length}</span>
              <span className='right'>{showMessage("totalDePaginas")}: {dataResult.reduce((sum, item) => sum + (item.PagesTotal || 0), 0)}</span>
            </span>
        </div>
      </div> 
      : <></>}
    </div>
  )};

export default LibraryDetails;