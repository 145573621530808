import React, { useEffect, } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { useSearchContext } from '../../context/SearchContext';
import { showMessage } from '../../languages/_showmessages';
import { useLibraryContext } from '../../context/LibraryContext';
import { isMobile } from 'react-device-detect';
import { useThumbsLoadContext } from '../../context/ThumbsLoadContext';

interface ShowPopupProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThumbsMenu: React.FC<ShowPopupProps> = ({ isVisible, setIsVisible }) => {
  const { Search } = useSearchContext();
  const { Library, setLibrary } = useLibraryContext();
  const { setThumbsLoad } = useThumbsLoadContext();

  const closePopupMenu = () => {
    setIsVisible(false);
  };

  const clickMenu = (tipo: number) => {
    if (Library != null) Library.thumbs = tipo ?? 0;
    setLibrary(Library);
    setThumbsLoad(true);
    closePopupMenu();
  };
  
  useEffect(() => {
    const handleClickOutside = (event : any) => {
      const popupElement = document.querySelector('.popup-class');
      if (popupElement && !popupElement.contains(event.target)) {
        closePopupMenu();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);


  return (
    <>
      {isVisible ?
        <>
          {isMobile ? 
            <>
              <Popup show={true} popupClass={'popup-class-mobile'} onClose={closePopupMenu} >
                <div className='popupThumbs'>
                  <div className='titlePopup'>{showMessage("miniaturas")}</div>
                  <ul>
                      <li onClick={() => clickMenu(1)}>{showMessage("paginas")}</li>                            
                      <li className={!(Search?.matchs)? 'disabledThumb' : ''} onClick={() => Search?.matchs !== null && clickMenu(2)}>{showMessage("ocorrencias")}</li>                            
                      <li onClick={() => clickMenu(3)}>{showMessage("capas")}</li>
                  </ul>
                </div>
              </Popup>
            </>
          :
            <>
              <Popup show={true} popupClass={'popup-class'} onClose={closePopupMenu} >
                <div className='popupThumbs'>
                  <div className='titlePopup'>{showMessage("miniaturas")}</div>
                  <ul>
                    <li onClick={() => clickMenu(1)}>{showMessage("paginas")}</li>                            
                    <li className={!(Search?.matchs)? 'disabledThumb' : ''} onClick={() => Search?.matchs !== null && clickMenu(2)}>{showMessage("ocorrencias")}</li>                            
                    <li onClick={() => clickMenu(3)}>{showMessage("capas")}</li>
                  </ul>
                </div>
              </Popup>
            </>
          }
          </>
        :
          <></>
        }
    </>
  );
};

export default ThumbsMenu;
