import React, { ReactNode, useContext, useEffect } from 'react';
import { ControlDataModel } from '../models/controlDataModel';

// Definindo o tipo para as props do provider
interface ControlContextProviderProps {
  children: ReactNode;
}

// Definindo o tipo para o contexto
interface ControlContextType {
  commandControlContext: ControlDataModel | null;
  setCommandControlContext: React.Dispatch<React.SetStateAction<ControlDataModel | null>>;
}

// Criando o contexto
const ControlContext = React.createContext<ControlContextType | undefined>(undefined);

// Função para salvar dados no localStorage
const saveToLocalCache = (data: ControlDataModel | null) => {
  localStorage.setItem('commandControlContext', JSON.stringify(data));
};

// Função para carregar dados do localStorage
const loadFromLocalCache = (): ControlDataModel | null => {
  const data = localStorage.getItem('commandControlContext');
  return data ? JSON.parse(data) : null;
};

// Criando o provider do contexto
export function ControlContextProvider({ children }: ControlContextProviderProps) {  
  const [commandControlContext, setCommandControlContext] = React.useState<ControlDataModel | null>(loadFromLocalCache());

  useEffect(() => {
    saveToLocalCache(commandControlContext);
  }, [commandControlContext]);
  
  return (
    <ControlContext.Provider value={{ commandControlContext, setCommandControlContext }}>
      {children}
    </ControlContext.Provider>
  );
}

// Criando um hook customizado para usar o contexto
export function useControlContext(): ControlContextType {
  const context = useContext(ControlContext);
  if (context === undefined) {
    throw new Error('useControlContext must be used within a ControlContextProvider');
  }
  return context;
}