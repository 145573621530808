import { ImageModel } from '../models/imageModel';
import { getDocReaderAPI, getShowLogs } from '../services/appSettings';
import { FolderAndPageModel } from '../models/folderAndPageModel';
import axios from 'axios';
import { BaseModel } from '../models/baseModel';

export const buildHash = (key: string,
  library: string,
  folderId: number,
  page: number): string => { return `${key}_${library}_${folderId}_${page}`; };

const baseUrl = getDocReaderAPI();

export const getFolderAndPage = async ( library: string, pageId: number, token: string): Promise<FolderAndPageModel | null> => {
  if (!baseUrl) {
    console.error('Environment variable REACT_APP_DOCREADERAPI is not set');
    throw new Error("DocReaderAPI Url not set");
  } 

  if ((library ?? '') === '' || (pageId ?? 0) === 0) return null;

  const url = new URL(`${baseUrl}/documents/getfolderandpage/${library}/${pageId}`);
  const headers = token !== "" ? {headers: {Authorization: `Bearer ${token}`}} : {headers: {Authorization: `none`}};

  var result: Promise<FolderAndPageModel> = axios.get(url.toString(), headers)
  .then(response => {
    var basedata = response.data as BaseModel;

    if (basedata.sucesso) {
      if (getShowLogs() === 'on') {console.log(basedata.mensagem);}
      var data = basedata.data as FolderAndPageModel;
      return data;
    }
    console.error(basedata.mensagem);
    throw new Error(basedata.mensagem);
  })
  .catch(error => {
    console.error(error);
    throw new Error(error.response.status);
  });

  return result;  
};

export const getImage = async (hash: string, library: string, folderId: number, page: number, token: string): Promise<ImageModel | null> => {
  var result = { base64: '', hash: undefined, sucesso: false } as ImageModel;

  if (!baseUrl) {
    console.error('Environment variable REACT_APP_DOCREADERAPI is not set');
    return result;
  }

  if (folderId === 0) { return null; }
  if (page === 0) { return null; }

  try {
    const url = new URL(`${baseUrl}/documents/${library}/${folderId}/${page}`);
    const headers = token !== "" ? {headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}`}} : {headers: { 'Content-Type': 'application/json', Authorization: `none`}};

    result.hash = hash;
    let response = await fetch(url.toString(), headers);

    if (!response.ok) {
      result.mensagem = 'Erro ao ler imagem: ' + response.statusText;
      result.hash = undefined;
      return result;
    }

    const blob = await response.blob();
    const bmp = await createImageBitmap(blob);

    const reader = new FileReader();
    reader.onloadend = function () {
      let image = reader.result as string;
      if (image === undefined) {
        result.sucesso = false;
        result.mensagem = "page_not_found";
        return result;
      }
      else {
        result.base64 = image;
        result.width = bmp.width;
        result.height = bmp.height;
        const originalSize = response.headers.get("OriginalSize")
        result.widthOriginal = parseInt(originalSize?.split(",")[0].substring(2) ?? '0');
        result.heightOriginal = parseInt(originalSize?.split(",")[1].substring(2) ?? '0');
        result.pageID = parseInt(response.headers.get("PageID") ?? '');
        result.sucesso = true;
      }
    }
    reader.readAsDataURL(blob);
  }
  catch (error) {
    result.sucesso = false;
    result.mensagem = error as string;
  }

  let nCount = 0;
  while (result.base64 === '' && !result.mensagem) {
    nCount++;
    await new Promise(r => setTimeout(r, 100));
    if (nCount > 50) {
      result.hash = undefined;
      result.sucesso = false;
      break;
    }
  }
  return result;
};